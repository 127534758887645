import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import { Layout, SafeImage } from "../components/common";
import { processHtml } from "../utils/processHtml";
import { canonicalize } from "../utils/canonicalize";

const Container = styled.div`
  background-color: #fff !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  h3 {
    margin-top: 0;
  }
  .book-cover {
    max-width: 50%;
    height: auto;
  }
  .form {
    max-width: 100%;
  }
  .two-column div {
    :nth-child(2) {
      margin-top: 2rem;
      @media ${props => props.theme.device.laptop} {
        margin-top: 0;
      }
    }
    @media ${props => props.theme.device.laptop} {
      margin-bottom: 0;
    }
  }
`;

const Training = ({ data }) => {
  const { html, plaintext, slug } = data.ghostPage;
  const { htmlX, jumbotronData } = processHtml(html, plaintext);

  let alt = slug.replace(/-/g, " ");

  return (
    <Layout
      title={data.ghostPage.title}
      subtitle={jumbotronData.subtitle}
      meta={{
        title: data.ghostPage.meta_title,
        description: data.ghostPage.meta_description,
        canonical: canonicalize(data.ghostPage.url, "resources"),
        featureImage: data.ghostPage.feature_image
      }}
      bgColor={jumbotronData.backgroundColor}
      jumbotronVariant="basic"
      illustrations={
        <SafeImage
          className="smaller-illustration"
          alt={alt}
          src={`resources/${slug}.svg`}
        />
      }
    >
      <Container className="container">
        {/* Ebook cover & Here is what you will learn in this book */}
        <div className="two-column">
          <SafeImage
            className="cook-cover"
            alt={`${alt} book cover`}
            src={`resources/covers/${slug}.png`}
          />
          <div>{htmlX[0]}</div>
        </div>
        {/* Form */}
        <div className="form">{htmlX[1]}</div>
        {/* Similar ebooks */}
        <div className="content-gray">{htmlX[2]}</div>
      </Container>
    </Layout>
  );
};

Training.propTypes = {
  data: PropTypes.shape({
    ghostPage: PropTypes.shape({
      title: PropTypes.string.isRequired,
      html: PropTypes.string.isRequired,
      feature_image: PropTypes.string
    }).isRequired
  }).isRequired,
  location: PropTypes.object.isRequired
};

export default Training;

export const postQuery = graphql`
  query($slug: String!) {
    ghostPage(slug: { eq: $slug }) {
      html
      plaintext
      title
      meta_title
      meta_description
      slug
      url
      feature_image
    }
  }
`;
